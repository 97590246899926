export const changelogList = [
    {
        versionNumber: 'v2.6.30',
        releaseDate: 'September 11, 2024',
        newFeatures: [
            {
                text: `Made left navigation bar to be opened by default`
            },
            {
                text: `Booking Closure Alert
                        ∙ Users will now be notified if they book an activity on a date with a booking closure.
                        ∙ Time entries on closed dates won't sync to metafinanzBusinessSystem and will be marked with an error status.`
            }
        ],
        bugFixes: [],
        deprecatedFeatures: []
    },
    {
        versionNumber: 'v2.6.20',
        releaseDate: 'September 11, 2024',
        newFeatures: [
            {
                text: `Moved navigation from right hand side button to a more slick and friendly left navigation bar (desktop view only)`
            },
            {
                text: `Moved user settings and language switch from the ribbon to the new left navigation bar (desktop view only)`
            },
            {
                text: `Left navigation is default shown as small but you can expand it by using the arrows on the bottom`
            },
            {
                text: `Added a Revert button in the failed activity recordings panel.
                       Use this button on an activity recording that now is in an ERROR state but previously had an OK state.
                       When clicking the button the activity recording will be reverted to the last OK state.`
            },
        ],
        bugFixes: [            
            {
                text: `Fixed the synchronization of Available and Total PT on projects.`
            },
        ],
        deprecatedFeatures: []
    },
    {
        versionNumber: 'v2.5.2',
        releaseDate: 'March 13, 2024',
        newFeatures: [
            {
                text: `Display billing type of each project`
            },
        ],
        bugFixes: [],
        deprecatedFeatures: []
    },
    {
        versionNumber: 'v2.5.12',
        releaseDate: 'December 5, 2023',
        newFeatures: [
            {
                text: `Grab a hot cocoa when booking activities because there is a snow forecast ahead.`
            },
            {
                text: `Enhancements to our Time-Booking feature! This update focuses on improving your experience in scheduling and managing activities.`
            },
            {
                text: `You can now create multiple activity templates from the new Activity Templates page. Each template comes with its own unique settings. These templates simplify the creation of future activities by pre-filling essential details like: activity comment and activity duration.`
            }
        ],
        bugFixes: [],
        deprecatedFeatures: [
            {
                text: "Removed icons for hide project and make project favorite from the project header. Functonality was moved in the edit panel of the project."
            }
        ]
    },
    {
        versionNumber: 'v2.4.35',
        releaseDate: 'November 16, 2023',
        newFeatures: [
            {
                text: `User Panel Integration: A new user panel has been added. Accessible by clicking on the user's name and choose Settings, this panel introduces several new functionalities:
                        ∙ Auto-Incrementation Toggle: Users can now enable or disable the auto-incrementation feature for activities dates.
                        ∙ Holiday Skipping Option: There's an option to enable or disable skipping holidays in the auto-increment date booking process.
                        ∙ Threshold Setting for Auto-Increment: Users can set a threshold in hours to determine when the auto-increment feature should take place.`
            }
        ],
        bugFixes: [            
            {
                text: "Addressed a bug on downloading attachments as PDF returning a 404 Not Found Error"
            }
        ],
        deprecatedFeatures: []
    },
    {
        versionNumber: 'v2.4.0',
        releaseDate: 'September 29, 2023',
        newFeatures: [
            {
                text: "Re-branding: Application has been re-branded to align with Metafinanz's new design, offering a fresh and cohesive user experience"
            },
            {
                text: "Layout Enhancements: Improved layout to optimize the application's appearance and usability on both small and medium-sized screens"
            },
            {
                text: `Easter Egg: Added a hidden easter egg, adding a fun and playful element for users to discover and enjoy. Can you find it?  
                
                I'm a shortcut that you seek,
                hidden away, not for the meek,             
                In the realm of keys, where commands reside,              
                Three modifiers combined, as you decide.          
                                
                First, a control of things, it's quite clear,             
                Next, an alternate path, let's persevere,                
                Lastly, a shift in the tide, you'll agree,                
                Combine them just right, and you shall see`
            }
        ],
        bugFixes: [            
            {
                text: "Addressed minor bugs and issues to ensure smoother and more reliable usage of the application"
            }
        ],
        deprecatedFeatures: []
    },
    {
        versionNumber: 'v2.3.2',
        releaseDate: 'July 19, 2023',
        newFeatures: [],
        bugFixes: [
            {
                text: 'Fixed notification overlaying with the ribbon on page scroll to bottom'
            }            
        ],
        deprecatedFeatures: []
    },
    {
        versionNumber: 'v2.3.1',
        releaseDate: 'July 5, 2023',
        newFeatures: [
            {
                text: 'Enhanced User Experience: Notifications are now displayed in a new, fresh way, ensuring they are both appealing and user-friendly'
            }
        ],
        bugFixes: [],
        deprecatedFeatures: []
    },
    {
        versionNumber: 'v2.3.0',
        releaseDate: 'June 21, 2023',
        newFeatures: [
            {
                text: 'Hide / Show projects from list'
            }, {
                text: 'Sorting of booking comments is now possible'
            }, {
                text: 'Predefine hours per booking comment'
            }, {
                text: 'Added projects grouping functionality. You can now create groups and store projects in them'
            }, {
                text: 'Introduced changelog page'
            }
        ],
        bugFixes: [],
        deprecatedFeatures: []
    },
    {
        versionNumber: 'v2.2.1',
        releaseDate: 'May 10, 2023',
        newFeatures: [ 
            {
                text: 'Improved copy functionality. Now you can update the booked hours for each day for the copied activity recording'
            }, {
                text: 'Added Albania in the bank holidays calendar'
            }   
        ],
        bugFixes: [],
        deprecatedFeatures: []
    },
    {
        versionNumber: 'v2.2.0',
        releaseDate: 'March 15, 2023',
        newFeatures: [ 
            {
                text: 'Copy activity recordings functionality is back desktop and mobile'
            }  
        ],
        bugFixes: [],
        deprecatedFeatures: []
    },
    {
        versionNumber: 'v2.1.1',
        releaseDate: 'December 22, 2022',
        newFeatures: [],
        bugFixes: [],
        deprecatedFeatures: [            
            {
                text: 'Removed create/manage personal projects'
            } 
        ]
    },
    {
        versionNumber: 'v2.1.0',
        releaseDate: 'September 14, 2022',
        newFeatures: [
            {
                text: 'Added bank holidays calendar for EU countries. You can choose your country and the bank holidays for the selected period in the calendar will be marked'
            } 
        ],
        bugFixes: [],
        deprecatedFeatures: []
    },
    {
        versionNumber: 'v2.0.0',
        releaseDate: 'June 23, 2022',
        newFeatures: [
            {
                text: 'Transitioned to a new user interface and experience'
            }, {
                text: 'Improved loading times'
            }, {
                text: 'Added generic error page with contact information'
            }, {
                text: 'Added maintenance page for when service is down'
            }, {
                text: 'Added posibility to switch between monthly or weekly booking'
            }, {
                text: 'Added posibility to dowload activity recordings as PDF'
            }, {
                text: 'Reworked booking comment selection'
            }    
        ],
        bugFixes: [
        ],
        deprecatedFeatures: [
            {
                text: 'Removed timer functionality'
            }, {
                text: 'Removed copy time record functionality'
            }, {
                text: 'Removed user booking formats'
            }
        ]
    }
]

// ex
// {
//     versionNumber: 'v2.0.1',
//     releaseDate: 'June 20, 2023',
//     newFeatures: [
//         {
//             text: 'Added a new feature 1',
//             linkText: 'text for a hyperlink' (Optiona)
//             link: 'url for a hyperlink' (Optiona)
//         },
//         {
//             text: 'Added a new feature 2'
//         }
//     ],
//     bugFixes: [
//         {
//             text: 'Fixed a bug'
//         },
//     }
//     deprecatedFeatures: [
//         {
//             text: 'We don't have this any more'
//         },
//     }
// },